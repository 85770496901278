<template>
  <div>
    <PageHeaderMarketing :image="data?.siteInfo?.logo" />
    <div class="pt-[100px]">
      <NuxtPageDependency>
        <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
      </NuxtPageDependency>

      <div>
        <ClientOnly>
          <DrupalMessages v-if="!isEditing" />
        </ClientOnly>

        <slot />
      </div>
    </div>

    <NuxtPageDependency>
      <PageFooterMarketing />
    </NuxtPageDependency>
  </div>
</template>

<script setup lang="ts">
import type { SiteInfoQuery, SiteVariablesFragment } from '#graphql-operations'

const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()
const breadcrumb = useBreadcrumbLinks()

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.value.accessToolbar,
)

const hostname = useHostName()

const { data } = await useAsyncData(async () => {
  const { value, addToCache } = await useDataCache<SiteInfoQuery>(
    'siteinfo' + hostname + language,
  )
  if (value) {
    return value
  }

  const data = await useGraphqlQuery('siteInfo').then((v) => {
    return v.data
  })
  await addToCache(data)
  return data
})

console.log('siteinfo', data.value)

// Farben holen
// useAsyncData, mit dataCache, sonst GraphQL-Query
// Mit useHead injecten?
// style attribute auf <html> tag setzen

const showBreadcrumb = computed(
  () =>
    route.meta.hideBreadcrumb !== true && route.meta.name !== 'drupal-route',
)

type ColorProperty = {
  name: string
  value: string
}

const colorStyles = computed(() => {
  const colors: (keyof SiteVariablesFragment)[] = [
    'primaryColor',
    'secondaryColor',
    'navColor',
    'footerColor',
    'linkColor',
  ]

  // Iterate over colors and create CSS variables
  return colors.reduce((acc, color) => {
    const colorValue = data.value?.siteInfo?.[color] as [ColorProperty]
    if (!colorValue) {
      return acc
    }

    return (
      acc +
      colorValue.reduce((innerAcc, { name, value }) => {
        return `${innerAcc}${name}: ${value}; `
      }, '')
    )
  }, '')
})

useHead({
  htmlAttrs: {
    lang: language.value,
    style: colorStyles,
  },
})
</script>
