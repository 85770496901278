<template>
  <div class="bg-primary-300">
    <MediaImage v-bind="image" :image-style="logoStyle" />
    <h1 class="mb-10 text-4xl">Header Placeholder</h1>
  </div>
</template>

<script setup lang="ts">
import type { SiteLogoFragment } from '#graphql-operations'

defineProps<{
  image?: SiteLogoFragment
}>()

const logoStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 150,
    },
  },
})
</script>
